<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_I_PRODUTOS')"
					class="container-add-new"
					v-bind:class="!product.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="linhas.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + linhas.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
				</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="md"
			>
				<div class="modal-edicao mb-3 modal-edicao-label">
					<b-tabs ref="tabs" class="text-parcela">
						<b-tab title="Geral" class="p-2 border border-top-none">
							<b-row class="mb-1">
								<b-col class="d-block text-parcela">
									<p class="label-conta">Produto/Serviço</p>
									<b-form-input
										name="description-produto"
										class="modal-edicao-input"
										type="text"
										v-model="productSelecionado.description"
										autocomplete="off"
										placeholder="Digite o nome do produto"
										v-validate="{ required: true }"
										:state="validateState('description-produto')"
									/>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col class="d-block text-parcela">
									<p class="label-conta">Referência</p>
									<b-form-input
										name="reference-conta"
										class="modal-edicao-input"
										type="text"
										v-model="productSelecionado.reference"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('reference-conta')"
									/>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col class="d-block text-parcela">
									<p class="label-conta">Tipo de Produto</p>
									<b-form-select
										name="id-tipo-produto"
										class="modal-edicao-input mr-1 mb-2"
										v-model="productSelecionado.idProductClass"
										v-validate="{ required: true }"
										:state="validateState('id-tipo-produto')"
										:options="productClass"
										:disabled="loading"
										@input="validateTypeAccount(productSelecionado.idProductClass)"
									/>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols=3 class="d-block text-parcela center mr-0 pr-0">
									<p class="label-conta">Eventual</p>
									<ToggleSwitch
										name="eventual"
										class="toggle-conta"
										:value="productSelecionado.eventual"
										@toggle="_ => changeToggle('eventual')"
									/>
								</b-col>
								<b-col cols=3 class="d-block text-parcela center mr-0 pr-0 pl-1" v-if="!productSelecionado.eventual && showComposed">
									<p class="label-conta">Composto</p>
									<ToggleSwitch
										name="composed"
										class="toggle-conta"
										:value="productSelecionado.composed"
										@toggle="_ => changeToggle('composed')"
									/>
								</b-col>
								<b-col class="d-block text-parcela pl-1">
									<p class="label-conta">Código Barras</p>
									<b-form-input
										name="barcode-produto"
										class="modal-edicao-input"
										type="text"
										pattern="[0-9]*"
										inputmode="numeric"
										v-model="productSelecionado.barcode"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('barcode-produto')"
										oninput="this.value = this.value.replace(/[^0-9]/g, '');"
									/>
								</b-col>
							</b-row>
						</b-tab>
							<b-tab title="Estoque" class="p-2 border border-top-none">
							<b-row class="mb-1">
								<b-col cols=4 class="d-block text-parcela mr-0 pr-0">
									<p class="label-conta">Quantidade</p>
									<b-form-input
										name="quantity-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.quantity"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('quantity-produto')"
										@input="updateValue('quantity')"
									/>
								</b-col>
								<b-col cols=4 class="d-block text-parcela mr-0 pr-0 pl-1">
									<p class="label-conta">Mínimo</p>
									<b-form-input
										name="minQty-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.minQty"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('minQty-produto')"
										@input="updateValue('minQty')"
									/>
								</b-col>
								<b-col cols=4 class="d-block text-parcela pl-1">
									<p class="label-conta">Máximo</p>
									<b-form-input
										name="maxQty-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.maxQty"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('maxQty-produto')"
										@input="updateValue('maxQty')"
									/>
								</b-col>
							</b-row>
							<b-row class="mb-1">
								<b-col cols=4 class="d-block text-parcela mr-0 pr-0">
									<p class="label-conta">Custo Anterior</p>
									<b-form-input
										name="previousCost-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.previousCost"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('previousCost-produto')"
										@input="updateValue('previousCost')"
									/>
								</b-col>
								<b-col cols=4 class="d-block text-parcela mr-0 pr-0 pl-1">
									<p class="label-conta">Custo Médio</p>
									<b-form-input
										name="averageCost-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.averageCost"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('averageCost-produto')"
										@input="updateValue('averageCost')"
									/>
								</b-col>
								<b-col cols=4 class="d-block text-parcela pl-1">
									<p class="label-conta">Custo Atual</p>
									<b-form-input
										name="currentCost-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.currentCost"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('currentCost-produto')"
										@input="updateValue('currentCost')"
									/>
								</b-col>
							</b-row>
							<b-row v-if="sale1" class="mb-1">
								<b-col class="d-block text-parcela mr-0 pr-0">
									<p class="label-conta">Preço de Revendedor</p>
									<b-form-input
										name="sale1-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.sale1"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('sale1-produto')"
										@input="updateValue('sale1')"
									/>
								</b-col>
								<b-col cols="4" class="d-block text-parcela pl-1">
									<p class="label-conta">Porcentagem</p>
									<b-form-input
										name="percentage1-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.percentage1"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('percentage1-produto')"
										@input="updateValue('percentage1')"
									/>
								</b-col>
							</b-row>
							<b-row v-if="sale2" class="mb-1">
								<b-col class="d-block text-parcela mr-0 pr-0">
									<p class="label-conta">Preço de Atacado</p>
									<b-form-input
										name="sale2-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.sale2"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('sale2-produto')"
										@input="updateValue('sale2')"
									/>
								</b-col>
								<b-col cols="4" class="d-block text-parcela pl-1">
									<p class="label-conta">Porcentagem</p>
									<b-form-input
										name="percentage2-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.percentage2"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('percentage2-produto')"
										@input="updateValue('percentage2')"
									/>
								</b-col>
							</b-row>
							<b-row v-if="sale3" class="mb-1">
								<b-col class="d-block text-parcela mr-0 pr-0">
									<p class="label-conta">Preço de Varejo</p>
									<b-form-input
										name="sale3-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.sale3"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('sale3-produto')"
										@input="updateValue('sale3')"
									/>
								</b-col>
								<b-col cols="4" class="d-block text-parcela pl-1">
									<p class="label-conta">Porcentagem</p>
									<b-form-input
										name="percentage3-produto"
										class="modal-edicao-input number-class"
										type="text"
										inputmode="numeric"
										v-model="productSelecionado.percentage3"
										autocomplete="off"
										placeholder=""
										v-validate="{ required: false }"
										:state="validateState('percentage3-produto')"
										@input="updateValue('percentage3')"
									/>
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="linhas.length" class="table-responsive">
			<DataTable v-if="!isMobile"
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="product"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_PRODUTOS')"
				:permissaoDelete="possuiPermissao('GER_D_PRODUTOS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
				:alignColumns="alignColumnsTable"
			/>
			<CardTransaction v-else
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_PRODUTOS')"
				:permissaoDelete="possuiPermissao('GER_D_PRODUTOS')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				:selectItem="false"
				:globalAcoes="false"
				@clickCheck="false"
				:colsDataCard="colsDataCard"
				:details="false"
				:showSelectItem="false"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import ToggleSwitch from "@/components/ToggleSwitch";

	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { ProductClassService } from "../../services/productClass";
	import { ProductService } from "../../services/product";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import { getDateMonthEnd, numberFormatted, stringToNumber, stringToDateUTC, validateNumber } from "@/helpers/common";
	import CardTransaction from "@/components/CardTransaction.vue";
	import { mapState } from "vuex";

	export default {
		components: {
			TemplateBase,
			ToggleSwitch,
			DataTable,
			CardTransaction
		},
		computed: mapState(["usuarioLogado"]),

		inject: ["parentValidator"],

		data () {
			return {
				product: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				productClass: [],
				productSelecionado: {
					idProduct: null,
					description: "",
					reference: "",
					barcode: "",
					idProductClass: null,
					quantity: 0,
					minQty: 0,
					maxQty: 0,
					composed: false,
					eventual: false,
					averageCost: 0,
					currentCost: 0,
					previousCost: 0,
					sale1: 0,
					percentage1: 0,
					sale2: 0,
					percentage2: 0,
					sale3: 0,
					percentage3: 0
				},
				showComposed: false,
				sale1: false,
				sale2: false,
				sale3: true,

				invalidForm: true,
				productClassService: new ProductClassService(),
				productService: new ProductService(),

				errMsg: "Nenhum produto cadastrado!",
				colunas: [
					"Descripção",
					"Tipo Produto",
					"Quantidade",
					"Preço de Revendedor",
					"Preço de Atacado",
					"Preço de Varejo",
					"Referência"

				],
				alignColumnsTable: [
					"left",
					"left",
					"right",
					"right",
					"right",
					"right",
					"left"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: 1
				},
				isMobile: false,
				colsDataCard: {
					0: {
						idx: 0,
						label: "Descripção",
						textAlign: "left",
						cols: 8,
						visible: true
					},
					1: {
						idx: 1,
						label: "Tipo Produto",
						textAlign: "left",
						cols: 4,
						visible: true
					},
					2: {
						idx: 2,
						label: "Quantidade",
						textAlign: "center",
						cols: 3,
						visible: true
					},
					3: {
						idx: 3,
						label: "Preço de Revendedor",
						textAlign: "right",
						cols: 3,
						visible: true
					},
					4: {
						idx: 4,
						label: "Preço de Atacado",
						textAlign: "right",
						cols: 3,
						visible: true
					},
					5: {
						idx: 5,
						label: "Preço de Varejo",
						textAlign: "right",
						cols: 3,
						visible: true
					},
					6: {
						idx: 6,
						label: "Referência",
						textAlign: "left",
						cols: 12,
						visible: true
					},
					7: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					}
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.showComposed = Boolean(this.usuarioLogado.config.showProductComposed);
			this.sale1 = Boolean(this.usuarioLogado.config.sale1);
			this.sale2 = Boolean(this.usuarioLogado.config.sale2);
			this.sale3 = Boolean(this.usuarioLogado.config.sale3);
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			await this.findProductClass();
			await this.findProduct();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findProduct();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findProduct();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findProduct();
			},

			async findProduct () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const result = await this.productService.listProduct({offset, limit, sortTable}) || {};
				this.product = result.rows.map(ele => ele);
				this.linhas = result.rows.reduce((acc, c) => [
					...acc,
					{
						idProduct: c.idProduct,
						cols: [
							c.description,
							c.nameProductClass,
							numberFormatted(c.quantity || "0"),
							numberFormatted(c.sale1 || "0"),
							numberFormatted(c.sale2 || "0"),
							numberFormatted(c.sale3 || "0"),
							c.reference || "-"
						]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			async findProductClass () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const result = await this.productClassService.listProductClass({offset, limit, sortTable}) || {};
				this.productClass = result.rows.reduce((acc, pc) => [
					...acc,
					{
						value: pc.idProductClass,
						text: pc.description
					}
				], []);
			},

			async mostrarEdicao (item_) {
				let item = {};
				if (item_.idProduct)
					[ item ] = this.product.filter(c => c.idProduct === item_.idProduct);

				const { idProduct,
					barcode,
					description,
					reference,
					idProductClass,
					quantity,
					minQty,
					maxQty,
					composed,
					eventual,
					averageCost,
					currentCost,
					previousCost,
					sale1,
					percentage1,
					sale2,
					percentage2,
					sale3,
					percentage3
				} = item;

				if (!idProduct)
					this.titleModalEdicao = "Cadastrar Novo Produto";
				else
					this.titleModalEdicao = "Editar Produto";

				this.invalidForm = true;
				this.productSelecionado.idProduct = idProduct || "";
				this.productSelecionado.barcode = barcode || "";
				this.productSelecionado.description = description || "";
				this.productSelecionado.reference = reference || "";
				this.productSelecionado.idProductClass = idProductClass || null;
				this.productSelecionado.quantity = numberFormatted(quantity || 0);
				this.productSelecionado.minQty = numberFormatted(minQty || 0);
				this.productSelecionado.maxQty = numberFormatted(maxQty || 0);
				this.productSelecionado.composed = composed || false;
				this.productSelecionado.eventual = eventual || false;
				this.productSelecionado.averageCost = numberFormatted(averageCost || 0);
				this.productSelecionado.currentCost = numberFormatted(currentCost || 0);
				this.productSelecionado.previousCost = numberFormatted(previousCost || 0);
				this.productSelecionado.sale1 = numberFormatted(sale1 || 0);
				this.productSelecionado.percentage1 = numberFormatted(percentage1 || 0);
				this.productSelecionado.sale2 = numberFormatted(sale2 || 0);
				this.productSelecionado.percentage2 = numberFormatted(percentage2 || 0);
				this.productSelecionado.sale3 = numberFormatted(sale3 || 0);
				this.productSelecionado.percentage3 = numberFormatted(percentage3 || 0);

				this.$refs.modalEdicao.show();
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};
				const payload = {
					idProduct: this.productSelecionado.idProduct,
					barcode: this.productSelecionado.barcode,
					description: this.productSelecionado.description,
					reference: this.productSelecionado.reference,
					idProductClass: this.productSelecionado.idProductClass,
					quantity: stringToNumber(this.productSelecionado.quantity),
					minQty: stringToNumber(this.productSelecionado.minQty),
					maxQty: stringToNumber(this.productSelecionado.maxQty),
					composed: this.productSelecionado.eventual ? false : this.productSelecionado.composed,
					eventual: this.productSelecionado.eventual,
					averageCost: stringToNumber(this.productSelecionado.averageCost),
					currentCost: stringToNumber(this.productSelecionado.currentCost),
					previousCost: stringToNumber(this.productSelecionado.previousCost),
					sale1: stringToNumber(this.productSelecionado.sale1),
					percentage1: stringToNumber(this.productSelecionado.percentage1),
					sale2: stringToNumber(this.productSelecionado.sale2),
					percentage2: stringToNumber(this.productSelecionado.percentage2),
					sale3: stringToNumber(this.productSelecionado.sale3),
					percentage3: stringToNumber(this.productSelecionado.percentage3)
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.productService.upsertProduct({payload});

						this.fecharModal("modalEdicao");
						this.findProduct();

						return {
							title: "Sucesso!",
							body: "Produto salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar o produto!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			async confirmaApagar (item_) {
				const [ item ] = this.product.filter((gu) => gu.idProduct === item_.idProduct);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o produto?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idProduct);
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (idProduct) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.productService.deleteProduct({idProduct});
						this.findProduct();

						return {
							title: "Sucesso!",
							body: "Produto deletado com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar o produto!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			limparModal () {
				this.productSelecionado.idProduct = null;
				this.productSelecionado.description = "";
				this.productSelecionado.reference = "";
				this.productSelecionado.barcode = "";
				this.productSelecionado.idProductClass = null;
				this.productSelecionado.quantity = 0;
				this.productSelecionado.minQty = 0;
				this.productSelecionado.maxQty = 0;
				this.productSelecionado.composed = false;
				this.productSelecionado.eventual = false;
				this.productSelecionado.averageCost = 0;
				this.productSelecionado.currentCost = 0;
				this.productSelecionado.previousCost = 0;
				this.productSelecionado.sale1 = 0;
				this.productSelecionado.percentage1 = 0;
				this.productSelecionado.sale2 = 0;
				this.productSelecionado.percentage2 = 0;
				this.productSelecionado.sale3 = 0;
				this.productSelecionado.percentage3 = 0;
			},

			changeToggle (key) {
				this.productSelecionado[key] = !this.productSelecionado[key];
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async updateValue (key) {
				const value = this.productSelecionado[key];
				this.productSelecionado[key] = await validateNumber(value);

				const cost = stringToNumber(this.productSelecionado.currentCost);
				const valueAltered = stringToNumber(this.productSelecionado[key]);
				if (cost <= 0 || valueAltered <= 0 ) return;
				let newValue = 0;
				switch (key) {
					case "sale1":
						newValue = ((valueAltered - cost) / cost) * 100;
						this.productSelecionado.percentage1 = numberFormatted(newValue || 0);
						break;
					case "sale2":
						newValue = ((valueAltered - cost) / cost) * 100;
						this.productSelecionado.percentage2 = numberFormatted(newValue || 0);
						break;
					case "sale3":
						newValue = ((valueAltered - cost) / cost) * 100;
						this.productSelecionado.percentage3 = numberFormatted(newValue || 0);
						break;
					case "percentage1":
						newValue = (cost * valueAltered / 100) + cost;
						this.productSelecionado.sale1 = numberFormatted(newValue || 0);
						break;
					case "percentage2":
						newValue = (cost * valueAltered / 100) + cost;
						this.productSelecionado.sale2 = numberFormatted(newValue || 0);
						break;
					case "percentage3":
						newValue = (cost * valueAltered / 100) + cost;
						this.productSelecionado.sale3 = numberFormatted(newValue || 0);
						break;
					default:
						break;

				}
			}
		}
	};
</script>

<style scoped>
	.border-top-none {
		border-top: none !important;
	}

	.number-class {
		text-align: right;
	}

	.text-conta {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.toggle-conta {
		/* display: flex; */
		margin-top: 7px;
		/* justify-content: center; */
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: 'Roboto Condensed Regular';
		font-size: 23px;
	}

	.modal-edicao-input {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
</style>
