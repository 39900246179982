import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class ProductClassService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listProductClass (filters) {
		const response = await this.axios.get("productClass/listProductClass", { params: { filters } });
		return response.data;
	}

	async upsertProductClass (payload) {
		const response = await this.axios.post("productClass/upsertProductClass", payload);
		return response.data;
	}

	async deleteProductClass (id) {
		const response = await this.axios.delete("productClass/deleteProductClass", { params: id });
		return response.data;
	}
}
